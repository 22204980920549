/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, ColumnWrapper, Subtitle, Text, Image, Title, SeparateLine, SeparateLineWrap, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu css-espvkv bg--top pb--12 pt--12" menu={true} name={"menu-2"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/1453/caf1041c3ce24064842a11e30903c555_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/1453/caf1041c3ce24064842a11e30903c555_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/1453/caf1041c3ce24064842a11e30903c555_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/1453/caf1041c3ce24064842a11e30903c555_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/1453/caf1041c3ce24064842a11e30903c555_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/1453/caf1041c3ce24064842a11e30903c555_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/1453/caf1041c3ce24064842a11e30903c555_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/1453/caf1041c3ce24064842a11e30903c555_s=3000x_.png);
    }
  
    `}>
          
          <Menu className="--left">
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style2 --center">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--600 swpf--uppercase ls--12" href={"#sluzby"} target={""} content={"Služby"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--600 swpf--uppercase ls--12" href={"/jazdecky-klub-i-horses"} style={{"maxWidth":310}} target={"_blank"} content={"Jazdecký klub I-Horses"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--600 swpf--uppercase ls--12" href={"/m-and-m"} target={""} content={"M&amp;M"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--600 swpf--uppercase ls--12" href={"#fotogalerie"} target={""} content={"FotogalÉria"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--600 swpf--uppercase ls--12" href={"/#rezervace"} target={""} content={"Objednávka"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--600 swpf--uppercase ls--12" href={"/#mapa"} target={""} content={"Mapa"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column className="css-jjy4z6 --style3 --center bg--top --full pb--30 pt--30" name={"uvod"} parallax={false} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17777/6ff744c37343424885284ff58ce43e50_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17777/6ff744c37343424885284ff58ce43e50_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17777/6ff744c37343424885284ff58ce43e50_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17777/6ff744c37343424885284ff58ce43e50_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17777/6ff744c37343424885284ff58ce43e50_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17777/6ff744c37343424885284ff58ce43e50_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17777/6ff744c37343424885284ff58ce43e50_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17777/6ff744c37343424885284ff58ce43e50_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="pb--40 pt--40" name={"sluzby"} style={{"paddingTop":null,"paddingBottom":null}} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box swpf--uppercase" content={"služby<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":700}} content={"Kompletné agroslužby, preprava, ustajnenie koní, jazdecký klub<span style=\"var(--color-variable-1);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"maxWidth":1868}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/1385f45f0ef8462b836b016d33ae42fa_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":299}} srcSet={"https://cdn.swbpg.com/t/17777/1385f45f0ef8462b836b016d33ae42fa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/1385f45f0ef8462b836b016d33ae42fa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/1385f45f0ef8462b836b016d33ae42fa_s=860x_.jpg 860w"} position={{"x":"-14.987447698744774%","y":"-9.90794979079498%"}}>
              </Image>

              <Subtitle className="subtitle-box swpf--uppercase mt--0" style={{"maxWidth":"","marginTop":null}} content={"Kosenie<br>Riadkovanie<br>Obracanie<br>Mulčovanie<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/14ae0e65f0e94117902e09e38d9ee0cb_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":326}} srcSet={"https://cdn.swbpg.com/t/17777/14ae0e65f0e94117902e09e38d9ee0cb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/14ae0e65f0e94117902e09e38d9ee0cb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/14ae0e65f0e94117902e09e38d9ee0cb_s=860x_.jpg 860w"} position={{"x":"-27.61506276150626%","y":"-0.41841004184100417%"}}>
              </Image>

              <Subtitle className="subtitle-box swpf--uppercase mt--0" style={{"maxWidth":"","marginTop":null}} content={"Lisovanie Sena <br>Slamy<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/aa5cfc0fd2e2432b9db49f82a2386a5c_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":272}} srcSet={"https://cdn.swbpg.com/t/17777/aa5cfc0fd2e2432b9db49f82a2386a5c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/aa5cfc0fd2e2432b9db49f82a2386a5c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/aa5cfc0fd2e2432b9db49f82a2386a5c_s=860x_.jpg 860w"} position={{"x":"-40.23616236162361%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box swpf--uppercase mt--0" style={{"maxWidth":"","marginTop":null}} content={"Preprava Koní"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/e1a910f5549d43e89e1a595d25d54739_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":292}} srcSet={"https://cdn.swbpg.com/t/17777/e1a910f5549d43e89e1a595d25d54739_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/e1a910f5549d43e89e1a595d25d54739_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/e1a910f5549d43e89e1a595d25d54739_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/e1a910f5549d43e89e1a595d25d54739_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box swpf--uppercase mt--0" style={{"maxWidth":"","marginTop":null}} content={"Preprava podvalníkom"}>
              </Subtitle>

              <Text className="text-box" content={"Preprava podvalníkom s rozmermi 2,10 x 4,15 m do váhy 2850kg ( rezivo, stavebný materiál, stavebné stroje , vozidlá , slamové, senové balíky)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"foj7u9ya4mp"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/bed7df4920f94318a37f417d18211144_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/17777/bed7df4920f94318a37f417d18211144_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/bed7df4920f94318a37f417d18211144_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/bed7df4920f94318a37f417d18211144_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/bed7df4920f94318a37f417d18211144_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box fs--26" content={"ZVOZ DREVA&nbsp;"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Ponúkame zvoz a prevoz dreva traktorovou vývozkou aj po verejných komunikáciach<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/06e85a73620e44b1b6418c5686cc7f53_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/17777/06e85a73620e44b1b6418c5686cc7f53_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/06e85a73620e44b1b6418c5686cc7f53_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/06e85a73620e44b1b6418c5686cc7f53_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/06e85a73620e44b1b6418c5686cc7f53_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17777/06e85a73620e44b1b6418c5686cc7f53_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"sluzby"} style={{"paddingTop":null,"paddingLeft":7,"paddingBottom":null}} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":700}} content={"<span style=\"var(--color-variable-1);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} style={{"paddingTop":null,"paddingBottom":null}} layout={"l12"} lightbox={true}>
          
          <ColumnWrap className="column__flex --full --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--center" src={"https://cdn.swbpg.com/t/17777/06be86cf5a9f488ca6b7b517c42415f5_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":854}} srcSet={"https://cdn.swbpg.com/t/17777/06be86cf5a9f488ca6b7b517c42415f5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/06be86cf5a9f488ca6b7b517c42415f5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/06be86cf5a9f488ca6b7b517c42415f5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/06be86cf5a9f488ca6b7b517c42415f5_s=1400x_.jpg 1400w"} position={{"x":"-22.239747634069406%","y":"0%"}}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/17777/a642134dad0a4879bcff166ba1120775_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17777/a642134dad0a4879bcff166ba1120775_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/a642134dad0a4879bcff166ba1120775_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/a642134dad0a4879bcff166ba1120775_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/a642134dad0a4879bcff166ba1120775_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/acee4f426f064cca8ff501674f4adf7b_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":842}} srcSet={"https://cdn.swbpg.com/t/17777/acee4f426f064cca8ff501674f4adf7b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/acee4f426f064cca8ff501674f4adf7b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/acee4f426f064cca8ff501674f4adf7b_s=860x_.jpg 860w"} position={{"x":"-50.249406175771966%","y":"-3.900237529691215%"}}>
              </Image>

              <Image className="--center" src={"https://cdn.swbpg.com/t/17777/8547d9fec80c4310b3bfc38892ada082_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":642}} srcSet={"https://cdn.swbpg.com/t/17777/8547d9fec80c4310b3bfc38892ada082_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/8547d9fec80c4310b3bfc38892ada082_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/8547d9fec80c4310b3bfc38892ada082_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/8547d9fec80c4310b3bfc38892ada082_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/45729ebc52cf48f1bbf659c55483eb0e_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17777/45729ebc52cf48f1bbf659c55483eb0e_s=350x_.jpg 350w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/17777/bf4f2a8685d04490aae8eb9d158e4174_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17777/bf4f2a8685d04490aae8eb9d158e4174_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/bf4f2a8685d04490aae8eb9d158e4174_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/bf4f2a8685d04490aae8eb9d158e4174_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/bf4f2a8685d04490aae8eb9d158e4174_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17777/bf4f2a8685d04490aae8eb9d158e4174_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"kontakt"} style={{"marginTop":null,"backgroundColor":"rgba(18, 18, 18, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box swpf--uppercase" style={{"maxWidth":""}} content={"<span style=\"color:var(--color-variable-1);\">I-agro s.r.o.<br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"I-Agro s.r.o.<br>Tehelná 26<br>Fiľakovo 98601<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--invert swpf--uppercase" style={{"maxWidth":200}} content={"objednajte si naše služby<br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr4 btn-box--shape2 btn-box--shadow4 fs--18 swpf--uppercase" use={"page"} href={"/#rezervace"} content={"Objednávka"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box swpf--uppercase" style={{"maxWidth":260}} content={"<span style=\"color: var(--color-variable-1);\">Info<br></span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18 subtitle-box--invert swpf--uppercase mt--08" style={{"marginTop":null}} content={"i-agro@i-agro.sk <br>+421 911 619 875<br>"}>
              </Subtitle>

              <Button className="btn-box" href={"https://www.facebook.com/pages/category/Agriculture/I-Agro-sro-103234904729201/"} content={"Facebook"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"informace"} layout={"l6"}>
          
          <SeparateLineWrap className="--full" style={{"backgroundColor":"rgba(242, 242, 242, 1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Fullmap className="--style2" name={"mapa"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"20"} place={"Fiľakovo, Tehelná 26"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}